<template>
  <ValidationProvider
    ref="providerRef"
    v-slot="{ errors, required, ariaInput, ariaMsg }"
    :class="`relative w-full appearance-none`"
    :tag="`div`"
    :vid="vid || name"
    :name="name || vid"
    :rules="rules"
  >
    <label
      class="block pb-1 w-full font-semibold leading-normal text-14px"
      @click="focus"
      :for="name"
      :class="{
        'text-gray-700': !errors[0],
        'text-red-600': errors[0] || customError != '',
      }"
    >
      <div v-if="label" class="flex">
        <span>{{ label }}</span>
        <span>{{ required ? ' *' : '' }}</span>
        <img
          v-if="isInfoEnabled"
          src="@/assets/icon/info.svg"
          class="cursor-pointer ml-1 w-4 h-4"
          v-tooltip="
            `<div class='max-w-xs p-1 text-xs'>${infoDescription}</div>`
          "
        />
      </div>
      <div v-if="showDescription" class="description">{{ description }}</div>
    </label>

    <template v-if="type === 'tel'">
      <vue-tel-input
        ref="inputRef"
        v-model="valueProxy"
        v-bind="{ ...$attrs }"
        v-on="getListeners"
        :class="{
          'border-gray-400 placeholder-gray-500 telinput':
            !errors[0] || customError == '',
          'border-red-600 placeholder-red-400 custom-phone': errors[0],
          'bg-oLightestGray text-oLightGray placeholder-oLightGray telinput': disabled,
          'has-value telinput': hasValue,
        }"
        validCharactersOnly
        :disabled="disabled"
        @input="onChangePhoneNumber"
      >
      </vue-tel-input>
    </template>

    <template v-else-if="type == 'textarea'">
      <textarea
        ref="inputRef"
        v-model="valueProxy"
        v-bind="{ ...ariaInput, ...$attrs }"
        v-on="getListeners"
        :disabled="disabled"
        class="block py-1 px-4 h-10 w-full leading-normal bg-white rounded border border-gray-400 outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500"
        :class="{
          'border-gray-400 placeholder-gray-500': !errors[0],
          'border-red-600 placeholder-red-400': errors[0],
          'has-value': hasValue,
          'bg-oLightestGray text-oLightGray placeholder-oLightGray': disabled,
        }"
      />
    </template>

    <template v-else-if="type === 'password'">
      <div class="relative">
        <input
          ref="inputRef"
          v-model="valueProxy"
          v-bind="{ ...ariaInput, ...$attrs }"
          v-on="getListeners"
          :type="typeProxy"
          :disabled="disabled"
          class="block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500"
          :class="{
            'border-gray-400 placeholder-gray-500': !errors[0],
            'border-red-600 placeholder-red-400': errors[0],
            'has-value': hasValue,
            'bg-oLightestGray text-oLightGray placeholder-oLightGray': disabled,
          }"
        />

        <div class="eye-body" @click="onTogglePasswordFeild">
          <i v-if="typeProxy === 'text'" class="far fa-eye" />
          <i v-if="typeProxy === 'password'" class="far fa-eye-slash" />
        </div>
      </div>
    </template>

    <template v-else-if="type === 'richselect'">
      <div
        v-if="haveUnitText"
        class="flex items-center  rounded-md"
        :class="`${getUnitPortionBgVariant}-background`"
      >
        <div class="w-full">
          <TRichSelect
            ref="inputRef"
            v-model="valueProxy"
            v-bind="{ ...ariaInput, ...$attrs }"
            v-on="getListeners"
            :delay="delaySearchTime"
            :disabled="disabled"
            :variant="{ danger: !!errors[0], readonly: disabled }"
            @focus="focusHandler"
            @blur="blurHandler"
          />
        </div>
        <div class="h-full  text-oWhite" :class="getUnitWidthVariantClass">
          <p class="py-1 text-center">{{ unitText }}</p>
        </div>
      </div>
      <div v-else>
        <TRichSelect
          ref="inputRef"
          v-model="valueProxy"
          v-bind="{ ...ariaInput, ...$attrs }"
          v-on="getListeners"
          :delay="delaySearchTime"
          :disabled="disabled"
          :variant="{ danger: !!errors[0], readonly: disabled }"
        />
      </div>
    </template>

    <template v-else>
      <div v-if="type === 'date'">
        <input
          ref="inputRef"
          v-model="valueProxy"
          v-bind="{ ...ariaInput, ...$attrs }"
          v-on="getListeners"
          :type="type"
          :max="max"
          :min="min"
          :disabled="disabled"
          class="block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500"
          :class="{
            'border-gray-400 placeholder-gray-500': !errors[0],
            'border-red-600 placeholder-red-400': errors[0],
            'bg-oLightestGray text-oLightGray placeholder-oLightGray': disabled,
            'has-value': hasValue,
          }"
        />
      </div>
      <div v-else>
        <div
          v-if="haveUnitText"
          class="flex items-center  rounded-md"
          :class="`${getUnitPortionBgVariant}-background`"
        >
          <div class="w-full">
            <input
              ref="inputRef"
              v-model="valueProxy"
              v-bind="{ ...ariaInput, ...$attrs }"
              v-on="getListeners"
              :type="type"
              :disabled="disabled"
              @focus="focusHandler"
              @blur="blurHandler"
              :max="max"
              :min="min"
              class=" block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500"
              :class="{
                'border-gray-400 placeholder-gray-500': !errors[0],
                'border-red-600 placeholder-red-400': errors[0],
                'bg-oLightestGray text-oLightGray placeholder-oLightGray': disabled,
                'has-value': hasValue,
                'outline-none px-2 appearance-none ring-0 focus:ring-0 focus:outline-none': isSearch,
              }"
            />
          </div>
          <div class="h-full  text-oWhite" :class="getUnitWidthVariantClass">
            <p class="py-1 text-center">{{ unitText }}</p>
          </div>
        </div>
        <div v-else>
          <input
            ref="inputRef"
            v-model="valueProxy"
            v-bind="{ ...ariaInput, ...$attrs }"
            v-on="getListeners"
            :type="type"
            :disabled="disabled"
            :max="max"
            :min="min"
            class=" block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500"
            :class="{
              'border-gray-400 placeholder-gray-500': !errors[0],
              'border-red-600 placeholder-red-400': errors[0],
              'bg-oLightestGray text-oLightGray placeholder-oLightGray': disabled,
              'has-value': hasValue,
              'outline-none px-2 appearance-none ring-0 focus:ring-0 focus:outline-none': isSearch,
            }"
          />
        </div>
      </div>
    </template>

    <!-- Suggestion -->
    <div v-if="haveSuggestion" class="flex items-center min-h-6">
      <span
        class="block text-xs "
        :class="
          isSuggestionAlert ? suggestionAlertClass : suggestionNonAlertClass
        "
        v-html="suggestionText"
      />
    </div>

    <!-- errors -->
    <div v-if="showError">
      <div
        v-if="!haveSuggestion && customError == ''"
        class="flex items-center min-h-6"
      >
        <span
          v-bind="ariaMsg"
          v-show="errors[0]"
          v-text="errors[0]"
          class="block text-xs text-red-600"
        />
      </div>

      <div v-if="customError != ''" class="flex items-center min-h-6">
        <span
          v-bind="ariaMsg"
          v-show="customError"
          v-text="customError"
          class="block text-xs text-red-600"
        />
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { debounce } from 'lodash-es'
// TODO: add custom error to the vee-validate instance
/*
 * props.type should be immutable, props.type is watched & synced with data.typegProxy
 * type should be used for checking the real input type, while typeProxy can be used for
 * mutating the type & use inside.
 * e.g. on showing & hiding password, it needs mutate the input type
 **/

const validTypes = [
  'url',
  'text',
  'email',
  'search',
  'number',

  'date',

  'textarea',

  'tel',
  'password',
  'richselect',
]

const validUnitWidthVariant = ['small', 'standard', 'large']

export default {
  name: 'AppInput',

  inheritAttrs: false,

  components: {},

  props: {
    vid: {
      type: String,
      default: undefined,
    },

    name: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'text',
      validator(value) {
        return validTypes.includes(value)
      },
    },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
    label: {
      type: String,
      default: '',
    },

    rules: {
      type: [Object, String],
      default: '',
    },

    value: {
      type: null,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    // todo: wip
    variant: {
      type: null,
      default: '',
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    delaySearchTime: {
      type: Number,
      required: false,
      default: 400,
    },
    isInfoEnabled: {
      type: Boolean,
      default: true,
    },
    infoDescription: {
      type: String,
      default: '',
    },
    showError: {
      type: Boolean,
      default: true,
    },
    showDescription: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    haveSuggestion: {
      type: Boolean,
      default: false,
    },
    isSuggestionAlert: {
      type: Boolean,
      default: false,
    },
    suggestionAlertClass: {
      type: String,
      default: 'text-red-500 font-semibold',
    },
    suggestionNonAlertClass: {
      type: String,
      default: 'text-gray-700',
    },
    suggestionText: {
      type: String,
      default: '',
    },
    haveUnitText: {
      type: Boolean,
      default: false,
    },
    unitText: {
      type: String,
      default: '',
    },
    unitWidthVariant: {
      type: String,
      default: 'standard',
      validator(value) {
        return validUnitWidthVariant.includes(value)
      },
    },
  },

  data: () => ({
    typeProxy: '',
    valueProxy: '',
    customError: '',
    unitPortionBgVariant: 'blur',
  }),

  computed: {
    hasValue() {
      return !!this.valueProxy
    },

    getListeners() {
      return Object.assign({}, this.$listeners, {
        // handle custom event as well as a fallback to the native events
        input: debounce((event) => {
          const e =
            this.type === 'richselect' || this.type === 'tel'
              ? event
              : event.target.value
          this.$emit('input', e)
        }, 400),
      })
    },
    getUnitPortionBgVariant() {
      return this.unitPortionBgVariant
    },
    getUnitWidthVariantClass() {
      return `${this.unitWidthVariant}-unit-width`
    },
  },

  watch: {
    value: {
      deep: false,
      immediate: true,
      handler(val) {
        if (val !== this.valueProxy) {
          this.valueProxy = val
        }
      },
    },

    type: {
      deep: false,
      immediate: true,
      handler(val) {
        if (val !== this.typeProxy) {
          this.typeProxy = val
        }
      },
    },

    // This is now handled by getListeners
    /* valueProxy: {
      deep: false,
      immediate: false,
      handler(val) {
        this.$emit('input', val)
      },
    }, */
  },

  methods: {
    onChangePhoneNumber(number, valid) {
      if (valid.isValid) {
        //true
        this.valueProxy = valid.number.input
        this.customError = ''
        // this.$emit('tel:valid', {
        //   valid: true,
        //   message: 'The phone number field is valid',
        // })
      } else {
        // this.$emit('tel:invalid', {
        //   valid: false,
        //   message: 'The phone number field must be a valid',
        // })
        // this.customError = 'The phone number field must be a valid'
      }
    },

    onTogglePasswordFeild() {
      this.typeProxy = this.typeProxy === 'password' ? 'text' : 'password'
    },

    focus() {
      this.$refs.inputRef.focus()
    },
    focusHandler() {
      this.unitPortionBgVariant = 'focus'
      console.log('handler-focus')
    },
    blurHandler() {
      this.unitPortionBgVariant = 'blur'
      console.log('handler-blur')
    },
  },
}
</script>

<style lang="scss" scoped>
.min-h-6 {
  min-height: 1.5rem;
}
.eye-body {
  position: absolute;
  top: 0px;
  right: 0px;
  top: 8px;
  right: 11px;
  cursor: pointer;
  i {
    color: #00cb5d;
  }
}

.description {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 3px;
  margin-bottom: 2px;
}

.focus-background {
  @apply bg-gray-900;
}

.blur-background {
  @apply bg-gray-600;
}

.small-unit-width {
  @apply w-16;
}
.standard-unit-width {
  @apply w-20;
}

.large-unit-width {
  @apply w-24;
}
</style>
