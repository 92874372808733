var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:"providerRef",class:"relative w-full appearance-none",attrs:{"tag":"div","vid":_vm.vid || _vm.name,"name":_vm.name || _vm.vid,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var required = ref.required;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
return [_c('label',{staticClass:"block pb-1 w-full font-semibold leading-normal text-14px",class:{
      'text-gray-700': !errors[0],
      'text-red-600': errors[0] || _vm.customError != '',
    },attrs:{"for":_vm.name},on:{"click":_vm.focus}},[(_vm.label)?_c('div',{staticClass:"flex"},[_c('span',[_vm._v(_vm._s(_vm.label))]),_c('span',[_vm._v(_vm._s(required ? ' *' : ''))]),(_vm.isInfoEnabled)?_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          ("<div class='max-w-xs p-1 text-xs'>" + _vm.infoDescription + "</div>")
        ),expression:"\n          `<div class='max-w-xs p-1 text-xs'>${infoDescription}</div>`\n        "}],staticClass:"cursor-pointer ml-1 w-4 h-4",attrs:{"src":require("@/assets/icon/info.svg")}}):_vm._e()]):_vm._e(),(_vm.showDescription)?_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.description))]):_vm._e()]),(_vm.type === 'tel')?[_c('vue-tel-input',_vm._g(_vm._b({ref:"inputRef",class:{
        'border-gray-400 placeholder-gray-500 telinput':
          !errors[0] || _vm.customError == '',
        'border-red-600 placeholder-red-400 custom-phone': errors[0],
        'bg-oLightestGray text-oLightGray placeholder-oLightGray telinput': _vm.disabled,
        'has-value telinput': _vm.hasValue,
      },attrs:{"validCharactersOnly":"","disabled":_vm.disabled},on:{"input":_vm.onChangePhoneNumber},model:{value:(_vm.valueProxy),callback:function ($$v) {_vm.valueProxy=$$v},expression:"valueProxy"}},'vue-tel-input',Object.assign({}, _vm.$attrs),false),_vm.getListeners))]:(_vm.type == 'textarea')?[_c('textarea',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.valueProxy),expression:"valueProxy"}],ref:"inputRef",staticClass:"block py-1 px-4 h-10 w-full leading-normal bg-white rounded border border-gray-400 outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500",class:{
        'border-gray-400 placeholder-gray-500': !errors[0],
        'border-red-600 placeholder-red-400': errors[0],
        'has-value': _vm.hasValue,
        'bg-oLightestGray text-oLightGray placeholder-oLightGray': _vm.disabled,
      },attrs:{"disabled":_vm.disabled},domProps:{"value":(_vm.valueProxy)},on:{"input":function($event){if($event.target.composing){ return; }_vm.valueProxy=$event.target.value}}},'textarea',Object.assign({}, ariaInput, _vm.$attrs),false),_vm.getListeners))]:(_vm.type === 'password')?[_c('div',{staticClass:"relative"},[((_vm.typeProxy)==='checkbox')?_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.valueProxy),expression:"valueProxy"}],ref:"inputRef",staticClass:"block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500",class:{
          'border-gray-400 placeholder-gray-500': !errors[0],
          'border-red-600 placeholder-red-400': errors[0],
          'has-value': _vm.hasValue,
          'bg-oLightestGray text-oLightGray placeholder-oLightGray': _vm.disabled,
        },attrs:{"disabled":_vm.disabled,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.valueProxy)?_vm._i(_vm.valueProxy,null)>-1:(_vm.valueProxy)},on:{"change":function($event){var $$a=_vm.valueProxy,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.valueProxy=$$a.concat([$$v]))}else{$$i>-1&&(_vm.valueProxy=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.valueProxy=$$c}}}},'input',Object.assign({}, ariaInput, _vm.$attrs),false),_vm.getListeners)):((_vm.typeProxy)==='radio')?_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.valueProxy),expression:"valueProxy"}],ref:"inputRef",staticClass:"block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500",class:{
          'border-gray-400 placeholder-gray-500': !errors[0],
          'border-red-600 placeholder-red-400': errors[0],
          'has-value': _vm.hasValue,
          'bg-oLightestGray text-oLightGray placeholder-oLightGray': _vm.disabled,
        },attrs:{"disabled":_vm.disabled,"type":"radio"},domProps:{"checked":_vm._q(_vm.valueProxy,null)},on:{"change":function($event){_vm.valueProxy=null}}},'input',Object.assign({}, ariaInput, _vm.$attrs),false),_vm.getListeners)):_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.valueProxy),expression:"valueProxy"}],ref:"inputRef",staticClass:"block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500",class:{
          'border-gray-400 placeholder-gray-500': !errors[0],
          'border-red-600 placeholder-red-400': errors[0],
          'has-value': _vm.hasValue,
          'bg-oLightestGray text-oLightGray placeholder-oLightGray': _vm.disabled,
        },attrs:{"disabled":_vm.disabled,"type":_vm.typeProxy},domProps:{"value":(_vm.valueProxy)},on:{"input":function($event){if($event.target.composing){ return; }_vm.valueProxy=$event.target.value}}},'input',Object.assign({}, ariaInput, _vm.$attrs),false),_vm.getListeners)),_c('div',{staticClass:"eye-body",on:{"click":_vm.onTogglePasswordFeild}},[(_vm.typeProxy === 'text')?_c('i',{staticClass:"far fa-eye"}):_vm._e(),(_vm.typeProxy === 'password')?_c('i',{staticClass:"far fa-eye-slash"}):_vm._e()])])]:(_vm.type === 'richselect')?[(_vm.haveUnitText)?_c('div',{staticClass:"flex items-center  rounded-md",class:(_vm.getUnitPortionBgVariant + "-background")},[_c('div',{staticClass:"w-full"},[_c('TRichSelect',_vm._g(_vm._b({ref:"inputRef",attrs:{"delay":_vm.delaySearchTime,"disabled":_vm.disabled,"variant":{ danger: !!errors[0], readonly: _vm.disabled }},on:{"focus":_vm.focusHandler,"blur":_vm.blurHandler},model:{value:(_vm.valueProxy),callback:function ($$v) {_vm.valueProxy=$$v},expression:"valueProxy"}},'TRichSelect',Object.assign({}, ariaInput, _vm.$attrs),false),_vm.getListeners))],1),_c('div',{staticClass:"h-full  text-oWhite",class:_vm.getUnitWidthVariantClass},[_c('p',{staticClass:"py-1 text-center"},[_vm._v(_vm._s(_vm.unitText))])])]):_c('div',[_c('TRichSelect',_vm._g(_vm._b({ref:"inputRef",attrs:{"delay":_vm.delaySearchTime,"disabled":_vm.disabled,"variant":{ danger: !!errors[0], readonly: _vm.disabled }},model:{value:(_vm.valueProxy),callback:function ($$v) {_vm.valueProxy=$$v},expression:"valueProxy"}},'TRichSelect',Object.assign({}, ariaInput, _vm.$attrs),false),_vm.getListeners))],1)]:[(_vm.type === 'date')?_c('div',[((_vm.type)==='checkbox')?_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.valueProxy),expression:"valueProxy"}],ref:"inputRef",staticClass:"block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500",class:{
          'border-gray-400 placeholder-gray-500': !errors[0],
          'border-red-600 placeholder-red-400': errors[0],
          'bg-oLightestGray text-oLightGray placeholder-oLightGray': _vm.disabled,
          'has-value': _vm.hasValue,
        },attrs:{"max":_vm.max,"min":_vm.min,"disabled":_vm.disabled,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.valueProxy)?_vm._i(_vm.valueProxy,null)>-1:(_vm.valueProxy)},on:{"change":function($event){var $$a=_vm.valueProxy,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.valueProxy=$$a.concat([$$v]))}else{$$i>-1&&(_vm.valueProxy=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.valueProxy=$$c}}}},'input',Object.assign({}, ariaInput, _vm.$attrs),false),_vm.getListeners)):((_vm.type)==='radio')?_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.valueProxy),expression:"valueProxy"}],ref:"inputRef",staticClass:"block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500",class:{
          'border-gray-400 placeholder-gray-500': !errors[0],
          'border-red-600 placeholder-red-400': errors[0],
          'bg-oLightestGray text-oLightGray placeholder-oLightGray': _vm.disabled,
          'has-value': _vm.hasValue,
        },attrs:{"max":_vm.max,"min":_vm.min,"disabled":_vm.disabled,"type":"radio"},domProps:{"checked":_vm._q(_vm.valueProxy,null)},on:{"change":function($event){_vm.valueProxy=null}}},'input',Object.assign({}, ariaInput, _vm.$attrs),false),_vm.getListeners)):_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.valueProxy),expression:"valueProxy"}],ref:"inputRef",staticClass:"block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500",class:{
          'border-gray-400 placeholder-gray-500': !errors[0],
          'border-red-600 placeholder-red-400': errors[0],
          'bg-oLightestGray text-oLightGray placeholder-oLightGray': _vm.disabled,
          'has-value': _vm.hasValue,
        },attrs:{"max":_vm.max,"min":_vm.min,"disabled":_vm.disabled,"type":_vm.type},domProps:{"value":(_vm.valueProxy)},on:{"input":function($event){if($event.target.composing){ return; }_vm.valueProxy=$event.target.value}}},'input',Object.assign({}, ariaInput, _vm.$attrs),false),_vm.getListeners))]):_c('div',[(_vm.haveUnitText)?_c('div',{staticClass:"flex items-center  rounded-md",class:(_vm.getUnitPortionBgVariant + "-background")},[_c('div',{staticClass:"w-full"},[((_vm.type)==='checkbox')?_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.valueProxy),expression:"valueProxy"}],ref:"inputRef",staticClass:" block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500",class:{
              'border-gray-400 placeholder-gray-500': !errors[0],
              'border-red-600 placeholder-red-400': errors[0],
              'bg-oLightestGray text-oLightGray placeholder-oLightGray': _vm.disabled,
              'has-value': _vm.hasValue,
              'outline-none px-2 appearance-none ring-0 focus:ring-0 focus:outline-none': _vm.isSearch,
            },attrs:{"disabled":_vm.disabled,"max":_vm.max,"min":_vm.min,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.valueProxy)?_vm._i(_vm.valueProxy,null)>-1:(_vm.valueProxy)},on:{"focus":_vm.focusHandler,"blur":_vm.blurHandler,"change":function($event){var $$a=_vm.valueProxy,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.valueProxy=$$a.concat([$$v]))}else{$$i>-1&&(_vm.valueProxy=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.valueProxy=$$c}}}},'input',Object.assign({}, ariaInput, _vm.$attrs),false),_vm.getListeners)):((_vm.type)==='radio')?_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.valueProxy),expression:"valueProxy"}],ref:"inputRef",staticClass:" block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500",class:{
              'border-gray-400 placeholder-gray-500': !errors[0],
              'border-red-600 placeholder-red-400': errors[0],
              'bg-oLightestGray text-oLightGray placeholder-oLightGray': _vm.disabled,
              'has-value': _vm.hasValue,
              'outline-none px-2 appearance-none ring-0 focus:ring-0 focus:outline-none': _vm.isSearch,
            },attrs:{"disabled":_vm.disabled,"max":_vm.max,"min":_vm.min,"type":"radio"},domProps:{"checked":_vm._q(_vm.valueProxy,null)},on:{"focus":_vm.focusHandler,"blur":_vm.blurHandler,"change":function($event){_vm.valueProxy=null}}},'input',Object.assign({}, ariaInput, _vm.$attrs),false),_vm.getListeners)):_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.valueProxy),expression:"valueProxy"}],ref:"inputRef",staticClass:" block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500",class:{
              'border-gray-400 placeholder-gray-500': !errors[0],
              'border-red-600 placeholder-red-400': errors[0],
              'bg-oLightestGray text-oLightGray placeholder-oLightGray': _vm.disabled,
              'has-value': _vm.hasValue,
              'outline-none px-2 appearance-none ring-0 focus:ring-0 focus:outline-none': _vm.isSearch,
            },attrs:{"disabled":_vm.disabled,"max":_vm.max,"min":_vm.min,"type":_vm.type},domProps:{"value":(_vm.valueProxy)},on:{"focus":_vm.focusHandler,"blur":_vm.blurHandler,"input":function($event){if($event.target.composing){ return; }_vm.valueProxy=$event.target.value}}},'input',Object.assign({}, ariaInput, _vm.$attrs),false),_vm.getListeners))]),_c('div',{staticClass:"h-full  text-oWhite",class:_vm.getUnitWidthVariantClass},[_c('p',{staticClass:"py-1 text-center"},[_vm._v(_vm._s(_vm.unitText))])])]):_c('div',[((_vm.type)==='checkbox')?_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.valueProxy),expression:"valueProxy"}],ref:"inputRef",staticClass:" block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500",class:{
            'border-gray-400 placeholder-gray-500': !errors[0],
            'border-red-600 placeholder-red-400': errors[0],
            'bg-oLightestGray text-oLightGray placeholder-oLightGray': _vm.disabled,
            'has-value': _vm.hasValue,
            'outline-none px-2 appearance-none ring-0 focus:ring-0 focus:outline-none': _vm.isSearch,
          },attrs:{"disabled":_vm.disabled,"max":_vm.max,"min":_vm.min,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.valueProxy)?_vm._i(_vm.valueProxy,null)>-1:(_vm.valueProxy)},on:{"change":function($event){var $$a=_vm.valueProxy,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.valueProxy=$$a.concat([$$v]))}else{$$i>-1&&(_vm.valueProxy=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.valueProxy=$$c}}}},'input',Object.assign({}, ariaInput, _vm.$attrs),false),_vm.getListeners)):((_vm.type)==='radio')?_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.valueProxy),expression:"valueProxy"}],ref:"inputRef",staticClass:" block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500",class:{
            'border-gray-400 placeholder-gray-500': !errors[0],
            'border-red-600 placeholder-red-400': errors[0],
            'bg-oLightestGray text-oLightGray placeholder-oLightGray': _vm.disabled,
            'has-value': _vm.hasValue,
            'outline-none px-2 appearance-none ring-0 focus:ring-0 focus:outline-none': _vm.isSearch,
          },attrs:{"disabled":_vm.disabled,"max":_vm.max,"min":_vm.min,"type":"radio"},domProps:{"checked":_vm._q(_vm.valueProxy,null)},on:{"change":function($event){_vm.valueProxy=null}}},'input',Object.assign({}, ariaInput, _vm.$attrs),false),_vm.getListeners)):_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.valueProxy),expression:"valueProxy"}],ref:"inputRef",staticClass:" block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500",class:{
            'border-gray-400 placeholder-gray-500': !errors[0],
            'border-red-600 placeholder-red-400': errors[0],
            'bg-oLightestGray text-oLightGray placeholder-oLightGray': _vm.disabled,
            'has-value': _vm.hasValue,
            'outline-none px-2 appearance-none ring-0 focus:ring-0 focus:outline-none': _vm.isSearch,
          },attrs:{"disabled":_vm.disabled,"max":_vm.max,"min":_vm.min,"type":_vm.type},domProps:{"value":(_vm.valueProxy)},on:{"input":function($event){if($event.target.composing){ return; }_vm.valueProxy=$event.target.value}}},'input',Object.assign({}, ariaInput, _vm.$attrs),false),_vm.getListeners))])])],(_vm.haveSuggestion)?_c('div',{staticClass:"flex items-center min-h-6"},[_c('span',{staticClass:"block text-xs ",class:_vm.isSuggestionAlert ? _vm.suggestionAlertClass : _vm.suggestionNonAlertClass,domProps:{"innerHTML":_vm._s(_vm.suggestionText)}})]):_vm._e(),(_vm.showError)?_c('div',[(!_vm.haveSuggestion && _vm.customError == '')?_c('div',{staticClass:"flex items-center min-h-6"},[_c('span',_vm._b({directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"block text-xs text-red-600",domProps:{"textContent":_vm._s(errors[0])}},'span',ariaMsg,false))]):_vm._e(),(_vm.customError != '')?_c('div',{staticClass:"flex items-center min-h-6"},[_c('span',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.customError),expression:"customError"}],staticClass:"block text-xs text-red-600",domProps:{"textContent":_vm._s(_vm.customError)}},'span',ariaMsg,false))]):_vm._e()]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }